.form-input {
  border-radius: 30px;
  border-width: 3px;
  border-color: #1aa179;
}

.form-style-light {
  color: #f5f5f5;
  background-color: #1f262a;
}

.form-style-light:focus {
  color: #f5f5f5;
  background-color: #1f262a;
}

.form-style-dark {
  color: #1f262a;
  background-color: #f5f5f5;
}

.form-style-dark:focus {
  color: #1f262a;
  background-color: #f5f5f5;
}

#connect-form {
  width: 18rem;
}
