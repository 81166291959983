#home-image {
  border-radius: 30px;
  border: 3px solid #1aa179;
  max-height: 60%;
  max-width: 60%;
}

#advice-icon {
  height: 20px;
  width: 20px;
}

.advice-card-light {
  border-radius: 30px;
  border: 3px solid #1aa179;
  background-color: #f5f5f5;
  color: #1f262a;
}

.advice-card-dark {
  border-radius: 30px;
  border: 3px solid #1aa179;
  background-color: #1f262a;
  color: #f5f5f5;
}
