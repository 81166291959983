#app {
  height: 100vh !important;
  width: 100% !important;
}

.light-mode-bg {
  background-color: #f5f5f5;
}

.dark-mode-bg {
  background-color: #1f262a;
}

#section-title-light {
  font-weight: bold;
  font-size: 60px;
  color: #1f262a;
}

#section-subtitle-light {
  font-size: 30px;
  color: #1f262a;
}

#section-text-light {
  font-size: 16px;
  color: #1f262a;
}

#section-title-dark {
  font-weight: bold;
  font-size: 60px;
  color: #f5f5f5;
}

#section-subtitle-dark {
  font-size: 30px;
  color: #f5f5f5;
}

#section-text-dark {
  font-size: 16px;
  color: #f5f5f5;
}

.btn {
  border-radius: 30px;
  border-width: 3px;
  border-color: #1aa179;
}

.btn:hover {
  border-radius: 30px;
  border-width: 3px;
  border-color: #1aa179;
}

#view-wrapper {
  max-height: 100vh;
  overflow-y: auto;
}

textarea {
  resize: none;
}
