#navBarIcon {
  position: absolute;
  left: 10px;
  bottom: 25px;
  z-index: 1;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(26, 161, 121)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-light .navbar-toggler {
  color: transparent;
  background-color: transparent;
  border-color: #1aa179;
}

.navbar-light .navbar-toggler:hover {
  box-shadow: 0 0 10px rgb(26, 161, 121);
}

#btn-medium-width {
  width: 160px !important;
}

#btn-large-width {
  width: 212px !important;
}

.button-style-light {
  color: #1aa179;
  border: 2px solid #1aa179;
}

.button-style-light:hover {
  color: #f5f5f5;
  transition: 0.5s ease;
  background-color: #1aa179;
}

.button-style-dark {
  color: #1aa179;
  border: 2px solid #1aa179;
}

.button-style-dark:hover {
  color: #f5f5f5;
  transition: 0.5s ease;
  background-color: #1aa179;
}

.btn-close-dark {
  font-size: 40px;
}

.current-view-btn {
  font-weight: bold;
  text-decoration-thickness: 3px !important;
  text-decoration: underline rgb(26, 161, 121);
}

.current-view-btn:hover {
  font-weight: bold;
  text-decoration: underline #f5f5f5;
}
