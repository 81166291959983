#notification {
  position: relative !important;
  max-height: 100%;
  overflow-y: scroll;
  border: solid 3px #1aa179;
  border-radius: 15px;
  width: 18rem;
  /* overflow-y: scroll; */
}

#notification ul li {
  list-style-type: circle;
}

#notifications-wrapper {
  position: fixed;
  right: 15px;
  bottom: 55px;
}
